@font-face {
    font-family: 'Futura Regular';
    font-style: normal;
    src: url('/static/fonts/futura/Futura-Book-font.ttf') format('truetype');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Futura Bold';
    font-style: normal;
    font-weight: normal;
    src: url('/static/fonts/futura/Futura-Heavy-font.ttf') format('truetype');
    font-display: swap;
  }
  
html{
    scroll-behavior: smooth;
}
 
body{
    scroll-behavior: smooth;
}
 
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}
.writeTextAnimation   {
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  font-size: 2rem;
  margin: 0 auto; 
  border-right: 0.15em solid transparent;
  animation: typing 4s steps(38) 1s 1 normal both, blink 1s steps(1) infinite;
}
 